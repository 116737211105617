import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Flowremind1 } from '../../../components/images/support/workflows/Flowremind1.generated';
import { Flowremind2 } from '../../../components/images/support/workflows/Flowremind2.generated';
import { Flowremind3 } from '../../../components/images/support/workflows/Flowremind3.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "send-a-reminder-for-a-workflow"
    }}>{`Send a Reminder for a Workflow`}</h1>
    <p>{`If a user has an `}<strong parentName="p">{`in-progess`}</strong>{` workflow we can send them a reminder to complete the workflow.`}</p>
    <p>{`Lets get started 🚀`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the users menu and click into the profile of the user you wish to send the reminder to.`}</li>
    </ol>

    <Flowremind1 mdxType="Flowremind1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the 3 dot menu on the `}<strong parentName="li">{`in-progess`}</strong>{` workflow.`}</li>
    </ol>

    <Flowremind2 mdxType="Flowremind2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Send reminder`}</strong>{`. This will send a notification to the user asking them to login to Canyou and complete
the workflow. The notification will include a link that allows the user to login.`}</li>
    </ol>

    <Flowremind3 mdxType="Flowremind3" />
    <h1 {...{
      "id": "great-work-you-have-sent-a-reminder-"
    }}>{`Great work you have sent a reminder 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      